import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.loading
    ? _c(
        VContainer,
        { attrs: { id: "dashboard", fluid: "", tag: "section" } },
        [
          _c("div", { staticClass: "banker-dashboard-grid" }, [
            _c("div", { staticClass: "banker-dashboard-section-values" }, [
              _c(
                "h1",
                { staticClass: "banker-dashboard-section-values-title" },
                [_vm._v(" " + _vm._s("DXA + " + _vm.partner.name) + " ")]
              ),
              _c(
                "p",
                { staticClass: "banker-dashboard-section-values-sub-title" },
                [_vm._v(" " + _vm._s(_vm.$t("banker_values_sub_title")) + " ")]
              ),
              _c(
                "div",
                { staticClass: "banker-dashboard-section-values-card-flex" },
                [
                  _c(
                    "div",
                    { staticClass: "banker-dashboard-section-values-card" },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("investment_quantify_title"))),
                      ]),
                      _c("h2", [
                        _vm._v(
                          " " + _vm._s(_vm.partner.totalInvestments) + " "
                        ),
                      ]),
                    ]
                  ),
                ]
              ),
              _c(
                "div",
                { staticClass: "banker-dashboard-section-values-cards" },
                [
                  _c(
                    "div",
                    [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "banker-dashboard-section-values-cards-title",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("investment_values")) + " "
                          ),
                        ]
                      ),
                      _c("CardFormattedCurrency", {
                        attrs: {
                          cardTitle: _vm.$t("amount"),
                          totalInvested: _vm.partner.totalInvested,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c(
                        "h2",
                        {
                          staticClass:
                            "banker-dashboard-section-values-cards-title",
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("allocations_pending")) + " "
                          ),
                        ]
                      ),
                      _c("CardFormattedCurrency", {
                        attrs: {
                          cardTitle: _vm.$t("amount"),
                          totalInvested: _vm.partner.totalRequestPending,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            _c("div", [
              _c(
                "h1",
                { staticClass: "banker-dashboard-title-section-links" },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("invite_new_investors_and_bankers")) +
                      " "
                  ),
                ]
              ),
              _c(
                "p",
                { staticClass: "banker-dashboard-sub-title-section-links" },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("invite_new_investors_and_bankers_sub_title")
                      ) +
                      " "
                  ),
                ]
              ),
              _vm.partner.alphaRegisterUrl
                ? _c("div", { staticClass: "banker-dashboard-section-link" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("invite_investor")))]),
                    _c("h2", [
                      _vm._v(_vm._s(_vm.$t("register_investor_alpha"))),
                    ]),
                    _c("p", [_vm._v(_vm._s(_vm.partner.alphaRegisterUrl))]),
                    _c(
                      "div",
                      {
                        staticClass: "banker-dashboard-section-link-copy-link",
                      },
                      [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.gs.copy_url_to_clipboard(
                                  _vm.partner.alphaRegisterUrl
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("click_to_copy_link")) + " "
                            ),
                          ]
                        ),
                        _c("font-awesome-icon", {
                          attrs: {
                            icon: "fa-solid fa-copy",
                            width: "16px",
                            height: "16px",
                            color: "var(--primary)",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.gs.copy_url_to_clipboard(
                                _vm.partner.alphaRegisterUrl
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
              _vm.partner.bankerRegisterUrl
                ? _c("div", { staticClass: "banker-dashboard-section-link" }, [
                    _c("span", [_vm._v(_vm._s(_vm.$t("invite_banker")))]),
                    _c("h2", [_vm._v(_vm._s(_vm.$t("register_banker")))]),
                    _c("p", [_vm._v(_vm._s(_vm.partner.bankerRegisterUrl))]),
                    _c(
                      "div",
                      {
                        staticClass: "banker-dashboard-section-link-copy-link",
                      },
                      [
                        _c(
                          "a",
                          {
                            on: {
                              click: function ($event) {
                                return _vm.gs.copy_url_to_clipboard(
                                  _vm.partner.bankerRegisterUrl
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              " " + _vm._s(_vm.$t("click_to_copy_link")) + " "
                            ),
                          ]
                        ),
                        _c("font-awesome-icon", {
                          attrs: {
                            icon: "fa-solid fa-copy",
                            width: "16px",
                            height: "16px",
                            color: "var(--primary)",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.gs.copy_url_to_clipboard(
                                _vm.partner.bankerRegisterUrl
                              )
                            },
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _vm._e(),
            ]),
          ]),
          _vm.users.length > 0
            ? _c(
                VContainer,
                {
                  staticClass: "white mt-4 rounded",
                  attrs: { tag: "section", fluid: "" },
                },
                [
                  _c(
                    VRow,
                    [
                      _c("h4", { staticClass: "h4" }, [
                        _vm._v(_vm._s(_vm.$tc("user", 2))),
                      ]),
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          staticClass: "mr-2",
                          attrs: {
                            rounded: "",
                            color: "primary",
                            loading: _vm.allocationsReportDownloading,
                          },
                          on: { click: _vm.download_allocations_report },
                        },
                        [
                          _c(VIcon, { staticClass: "mr-2" }, [
                            _vm._v("mdi-download-box"),
                          ]),
                          _c("span", { staticStyle: { "font-size": "13px" } }, [
                            _vm._v(
                              _vm._s(_vm.$t("download_allocations_report"))
                            ),
                          ]),
                        ],
                        1
                      ),
                      _c(
                        VBtn,
                        {
                          attrs: {
                            rounded: "",
                            color: "primary",
                            loading: _vm.investorsReportDownloading,
                          },
                          on: { click: _vm.download_investors_report },
                        },
                        [
                          _c(VIcon, { staticClass: "mr-2" }, [
                            _vm._v("mdi-download-box"),
                          ]),
                          _c("span", { staticStyle: { "font-size": "13px" } }, [
                            _vm._v(_vm._s(_vm.$t("download_investors_report"))),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c("InvestorsTable", {
                    attrs: {
                      Users: _vm.users.map(function (x) {
                        return x.user
                      }),
                    },
                  }),
                  _c(
                    VRow,
                    [
                      _c(VSpacer),
                      _c(
                        VBtn,
                        {
                          staticStyle: { "margin-right": "40px" },
                          attrs: { text: "", color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.$router.push(
                                "/" + _vm.partnerId + "/investors"
                              )
                            },
                          },
                        },
                        [_vm._v(_vm._s(_vm.$t("see_more")))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      )
    : _c(
        VContainer,
        [
          _c(VProgressCircular, {
            staticStyle: { "margin-left": "50%" },
            attrs: { indeterminate: "", size: "70", color: "primary" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }