var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-formatted-currency-container" },
    [
      _vm._l(_vm.totalInvested, function (item, i) {
        return _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.totalInvested.length,
                expression: "totalInvested.length",
              },
            ],
            key: i,
          },
          [
            _c("div", { staticClass: "card-formatted-currency-bg" }, [
              _c("span", [
                _vm._v(
                  _vm._s(
                    _vm.cardTitle +
                      " (" +
                      _vm.getCurrencyPrefix(item.currency) +
                      ")"
                  )
                ),
              ]),
              _c("h1", [
                _vm._v(_vm._s(_vm.formatCurrency(item.currency, item.value))),
              ]),
            ]),
          ]
        )
      }),
      !_vm.totalInvested.length
        ? _c("div", { staticClass: "card-formatted-currency-bg" }, [
            _c("span", [_vm._v(_vm._s(_vm.cardTitle + " (-)"))]),
            _c("h1", [_vm._v("-")]),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }